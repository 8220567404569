import React, {useEffect, useMemo, useState} from 'react';
import {Select} from "antd";
import CustomerService from "@iso/cra/src/services/CustomerService";
import ProjectService from "../services/ProjectService";

export default props => {

    const [houses, setHouses] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        ProjectService.getExistingElement(props.customerId, props.kind)
            .then((d) => {
                const nameField = props.nameField ? props.nameField : "name";
                setLoading(false);
                const data = d.map((d) => {return {
                    label: d[nameField],
                    value: d._id,
                    id: d._id
                }});
                setHouses(data);
                if (data.length > 0 && props.onSelection) {
                    setTimeout(() => {
                        props.onSelection(data[data.length-1].value);
                    }, 200);
                }
            });
    }, []);

    return (
        <Select options={houses}
                loading={loading}
                showSearch={true}
                onChange={(e) => {
                    return props.onSelection(e && e.value ? e.value : e);
                }}
                value={props.value ? houses.find((r) => r.value == props.value) : props.value}
                style={{width: "100%"}}></Select>
    );
};
